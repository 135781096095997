<template>
  <div>
    <!-- pic8D9C7826F04CB99EB61BB806D46AD774.jpg -->
    <!-- download57F779B745450EEDB2B107B8F26C90B3.flv -->
    <video class="rounded"
           src="@/assets/news-network/videos/lup-2008.mp4"
           poster="@/assets/news-network/lu-partners-event-2008.jpg"
           onclick="this.paused ? this.play() : this.pause();">
    </video>
    <p>
      See a special video summary of the LUP event, held in Colorado during June 2008.
    </p>
    <p>
      To read more about this event check out our behind the scenes story
      <router-link :to="{ name: 'story', params: {id: 80859} }">here</router-link>.
    </p>
  </div>
</template>
